/* You can add global styles to this file, and also import other style files */
/*================================================
Default CSS
=================================================*/
body {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #6c6377;
}

img {
  max-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  color: #301d44;
  font-weight: 600;
}

a {
  transition: all 0.7s;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

.mr-3, .mx-3 {
  margin-right: 1rem !important;
}

button:focus {
  outline: 0;
}

p {
  margin-bottom: 10px;
  line-height: 1.7;
  color: #6c6377;
}
p:last-child {
  margin-bottom: 0;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-70 {
  padding-bottom: 70px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.mr-4, .mx-4 {
  margin-right: 1.5rem !important;
}

.d-table {
  width: 100%;
  height: 100%;
}
.d-table .d-table-cell {
  vertical-align: middle;
}

.default-btn-one {
  background-color: #f7941d;
  color: #ffffff;
  display: inline-block;
  padding: 15px 30px;
  border-radius: 2px;
  border: 1px solid #f7941d;
}
.default-btn-one:hover {
  background: #8732de;
  border: 1px solid #8732de;
  color: #ffffff;
}

.default-btn-two {
  background-color: #8732de;
  color: #ffffff;
  display: inline-block;
  padding: 15px 30px;
  border-radius: 2px;
  border: 1px solid #8732de;
}
.default-btn-two:hover {
  background: #f7941d;
  border-color: #f7941d;
  color: #ffffff;
}

.section-title {
  text-align: center;
  margin-bottom: 60px;
  max-width: 630px;
  margin-left: auto;
  margin-right: auto;
}
.section-title span {
  font-size: 13px;
  font-weight: 600;
  display: block;
  margin-bottom: 15px;
  color: #f21860;
  line-height: 1;
}
.section-title h2 {
  font-size: 38px;
  line-height: 1.3;
  margin-bottom: 0;
}

.bg-f9f9f9 {
  background-color: #f9f9f9;
}

/* shape Style */
.shape-one {
  position: absolute;
  max-width: 145px;
  left: 0;
  top: -20px;
  z-index: -1;
}

.shape-two {
  position: absolute;
  right: 70px;
  top: 50px;
}
.shape-two .shape-1 {
  position: absolute;
  top: -67px;
  left: 50px;
  z-index: -1;
}

.shape-three {
  position: absolute;
  right: 30px;
  bottom: 80px;
}

.shape-four {
  position: absolute;
  right: 85px;
  top: -50px;
}

/*================================================
Top Heder
=================================================*/
.header-area {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1041;
  width: 100%;
}

.top-header {
  background: #8732de;
  padding: 10px 0;
}
.top-header .left-info {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.top-header .left-info li {
  display: inline-block;
  font-size: 14px;
  color: #ffffff;
  margin-right: 20px;
}
.top-header .left-info li:last-child {
  margin-right: 0;
}
.top-header .left-info li a {
  color: #ffffff;
}
.top-header .left-info li a i {
  margin-right: 5px;
  color: #ffffff;
  transition: all 0.7s;
}
.top-header .left-info li a:hover {
  color: #f7941d;
}
.top-header .left-info li a:hover i {
  color: #f7941d;
}
.top-header .right-info {
  list-style-type: none;
  margin-bottom: 0;
  text-align: right;
  padding: 0;
}
.top-header .right-info li {
  display: inline-block;
  font-size: 14px;
  color: #ffffff;
}
.top-header .right-info li.mr-20 {
  margin-right: 20px;
}
.top-header .right-info li:last-child {
  margin-right: 0;
}
.top-header .right-info li a {
  color: #ffffff;
}
.top-header .right-info li a i {
  margin-right: 5px;
  background: #ffffff;
  color: #301d44;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 2px;
  transition: all 0.7s;
}
.top-header .right-info li a:hover {
  color: #f7941d;
}
.top-header .right-info li a:hover i {
  background: #f7941d;
  color: #ffffff;
}

/*================================================
Navbar Area
=================================================*/
.navbar-area {
  background-color: transparent;
  width: 100%;
  height: auto;
  z-index: 999;
}
.navbar-area.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 5px;
  padding-bottom: 5px;
}
.navbar-area.navbar-color-white.is-sticky {
  background-color: #301d44 !important;
}

.floza-responsive-nav {
  display: none;
}

.floza-nav {
  background: #ffffff;
  padding: 3px 0;
}
.floza-nav .navbar {
  position: inherit;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.floza-nav .navbar .navbar-brand {
  font-size: inherit;
  line-height: 1;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}
.floza-nav .navbar ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.floza-nav .navbar .navbar-nav.ml-50 {
  margin-left: 50px;
}
.floza-nav .navbar .navbar-nav .nav-item {
  position: relative;
  margin-left: 15px;
  margin-right: 15px;
}
.floza-nav .navbar .navbar-nav .nav-item a {
  color: #301d44;
  font-size: 15px;
  font-weight: 500;
  padding-left: 0;
  padding-right: 0;
  padding-top: 20px;
  padding-bottom: 20px;
}
.floza-nav .navbar .navbar-nav .nav-item a i {
  font-size: 20px;
  line-height: 0;
  position: relative;
  top: 4px;
}
.floza-nav .navbar .navbar-nav .nav-item a:hover, .floza-nav .navbar .navbar-nav .nav-item a:focus, .floza-nav .navbar .navbar-nav .nav-item a.active {
  color: #f7941d;
}
.floza-nav .navbar .navbar-nav .nav-item:last-child {
  margin-right: 0;
}
.floza-nav .navbar .navbar-nav .nav-item:first-child {
  margin-left: 0;
}
.floza-nav .navbar .navbar-nav .nav-item:hover a, .floza-nav .navbar .navbar-nav .nav-item.active a {
  color: #f7941d;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  border: none;
  top: 65px;
  left: 0;
  z-index: 99;
  opacity: 0;
  width: 250px;
  display: block;
  border-radius: 0;
  padding: 10px 0;
  margin-top: 15px;
  position: absolute;
  visibility: hidden;
  background: #ffffff;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li {
  margin: 0;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  padding: 6px 20px;
  position: relative;
  display: block;
  color: #301d44;
  font-size: 14px;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li a i {
  margin: 0;
  position: absolute;
  top: 50%;
  font-size: 20px;
  transform: translateY(-50%);
  right: 15px;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover, .floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus, .floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #f7941d;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  left: 100%;
  margin-top: 15px;
  visibility: hidden;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #301d44;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover, .floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus, .floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #f7941d;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  left: 250px;
  visibility: hidden;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #301d44;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #f7941d;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  left: 100%;
  visibility: hidden;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #301d44;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #f7941d;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  left: 250px;
  visibility: hidden;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #301d44;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #f7941d;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  left: 100%;
  visibility: hidden;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #301d44;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #f7941d;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  left: 250px;
  visibility: hidden;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #301d44;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #f7941d;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #f7941d;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #f7941d;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #f7941d;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #f7941d;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: #f7941d;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: #f7941d;
}
.floza-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}
.floza-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}
.floza-nav .navbar .others-option {
  background-color: #7d7d7d;
  border-radius: 30px;
  padding: 10px 18px 3px;
  margin-left: auto;
}
.floza-nav .navbar .others-option .option-item {
  margin-left: 15px;
  padding-left: 15px;
  position: relative;
}
.floza-nav .navbar .others-option .option-item::before {
  content: "";
  position: absolute;
  left: -2px;
  top: -4px;
  background-color: #fa6a45;
  height: 25px;
  width: 1.5px;
}
.floza-nav .navbar .others-option .option-item:first-child {
  margin-left: 0;
  padding-left: 0;
}
.floza-nav .navbar .others-option .option-item:first-child::before {
  display: none;
}
.floza-nav .navbar .others-option .side-menu-btn i {
  cursor: pointer;
  font-size: 35px;
  position: relative;
  top: 4px;
  line-height: 0;
  transition: all 0.7s;
  color: #ffffff;
}
.floza-nav .navbar .others-option .side-menu-btn i:hover {
  color: #ffffff;
}
.floza-nav .navbar .others-option .search-box i {
  cursor: pointer;
  font-size: 22px;
  color: #ffffff;
  position: relative;
  top: -2px;
  line-height: 1;
  transition: all 0.7s;
}
.floza-nav .navbar .others-option .search-box i:hover {
  color: #ffffff;
}
.floza-nav .navbar .others-option .cart-btn a {
  display: inline-block;
  position: relative;
  font-size: 25px;
  color: #ffffff;
  line-height: 1;
  padding-right: 10px;
}
.floza-nav .navbar .others-option .cart-btn a span {
  position: absolute;
  right: 0;
  top: -3px;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  background-color: #301d44;
  font-size: 14px;
  font-weight: 600;
}

@media only screen and (max-width: 991px) {
  .navbar-area {
    background-color: #ffffff;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .navbar-area.is-sticky {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .floza-responsive-nav {
    display: none;
  }
  .floza-responsive-nav .floza-responsive-menu {
    position: relative;
  }
  .floza-responsive-nav .floza-responsive-menu.mean-container .mean-nav {
    margin-top: 50px;
  }
  .floza-responsive-nav .floza-responsive-menu.mean-container .mean-nav ul {
    font-size: 15px;
  }
  .floza-responsive-nav .floza-responsive-menu.mean-container .mean-nav ul li a.active {
    color: #f7941d;
  }
  .floza-responsive-nav .floza-responsive-menu.mean-container .mean-nav ul li li a {
    font-size: 14.5px;
  }
  .floza-responsive-nav .floza-responsive-menu.mean-container .navbar-nav {
    overflow-y: scroll;
    height: 308px;
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
  }
  .floza-responsive-nav .mean-container a.meanmenu-reveal {
    color: #f7941d;
  }
  .floza-responsive-nav .mean-container a.meanmenu-reveal span {
    background: #f7941d;
  }
  .floza-responsive-nav .others-option {
    display: none !important;
    background-color: #f7941d;
    border-radius: 30px;
    padding: 10px 18px 3px;
  }
  .floza-responsive-nav .others-option .option-item {
    margin-left: 15px;
    padding-left: 15px;
    position: relative;
  }
  .floza-responsive-nav .others-option .option-item::before {
    content: "";
    position: absolute;
    left: -2px;
    top: -4px;
    background-color: #fa6a45;
    height: 25px;
    width: 1.5px;
  }
  .floza-responsive-nav .others-option .option-item:first-child {
    margin-left: 0;
    padding-left: 0;
  }
  .floza-responsive-nav .others-option .option-item:first-child::before {
    display: none;
  }
  .floza-responsive-nav .others-option .side-menu-btn i {
    cursor: pointer;
    font-size: 35px;
    position: relative;
    top: 4px;
    line-height: 0;
    transition: all 0.7s;
    color: #ffffff;
  }
  .floza-responsive-nav .others-option .side-menu-btn i:hover {
    color: #ffffff;
  }
  .floza-responsive-nav .others-option .search-box i {
    cursor: pointer;
    font-size: 22px;
    color: #ffffff;
    position: relative;
    top: -2px;
    line-height: 1;
    transition: all 0.7s;
  }
  .floza-responsive-nav .others-option .search-box i:hover {
    color: #ffffff;
  }
  .floza-responsive-nav .others-option .cart-btn a {
    display: inline-block;
    position: relative;
    font-size: 25px;
    color: #ffffff;
    line-height: 1;
    padding-right: 10px;
  }
  .floza-responsive-nav .others-option .cart-btn a span {
    position: absolute;
    right: 0;
    top: -3px;
    width: 18px;
    height: 18px;
    text-align: center;
    line-height: 20px;
    border-radius: 50%;
    background-color: #301d44;
    font-size: 14px;
    font-weight: 600;
  }
  .floza-responsive-nav .logo {
    position: relative;
    width: 60%;
    z-index: 999;
  }
  .floza-nav {
    display: block;
  }
  .navbar-color-white .floza-responsive-nav .mean-container a.meanmenu-reveal {
    color: #ffffff;
  }
  .navbar-color-white .floza-responsive-nav .mean-container a.meanmenu-reveal span {
    background: #ffffff;
  }
  .navbar-color-white .others-option-for-responsive .dot-menu .inner .circle {
    background-color: #ffffff;
  }
  .others-option-for-responsive {
    display: block;
  }
}
/*================================================
Search Overlay CSS
=================================================*/
.search-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}
.search-overlay .search-overlay-layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  transform: translateX(100%);
}
.search-overlay .search-overlay-layer:nth-child(1) {
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out 0s;
}
.search-overlay .search-overlay-layer:nth-child(2) {
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease-in-out 0.3s;
}
.search-overlay .search-overlay-layer:nth-child(3) {
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  transition: all 0.9s ease-in-out 0.6s;
}
.search-overlay .search-overlay-close {
  position: absolute;
  top: 40px;
  right: 40px;
  width: 50px;
  z-index: 2;
  text-align: center;
  cursor: pointer;
  padding: 10px;
  transition: all 0.9s ease-in-out 1.5s;
  opacity: 0;
  visibility: hidden;
}
.search-overlay .search-overlay-close .search-overlay-close-line {
  width: 100%;
  height: 3px;
  float: left;
  margin-bottom: 5px;
  background-color: #ffffff;
  transition: all 500ms ease;
}
.search-overlay .search-overlay-close .search-overlay-close-line:nth-child(1) {
  transform: rotate(45deg);
}
.search-overlay .search-overlay-close .search-overlay-close-line:nth-child(2) {
  margin-top: -7px;
  transform: rotate(-45deg);
}
.search-overlay .search-overlay-close:hover .search-overlay-close-line {
  background: #f7941d;
  transform: rotate(180deg);
}
.search-overlay .search-overlay-form {
  transition: all 0.9s ease-in-out 1.4s;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 2;
  max-width: 500px;
  width: 100%;
  padding: 0 16px;
}
.search-overlay .search-overlay-form form {
  position: relative;
}
.search-overlay .search-overlay-form form .input-search {
  display: block;
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 30px;
  color: #301d44;
  padding: 3px 0 0 25px;
}
.search-overlay .search-overlay-form form .input-search::placeholder {
  transition: all 0.7s;
  letter-spacing: 0.5px;
  color: #301d44;
}
.search-overlay .search-overlay-form form .input-search:focus::placeholder {
  color: transparent;
}
.search-overlay .search-overlay-form form button {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 50px;
  color: #ffffff;
  height: 50px;
  border-radius: 50%;
  background-color: #f7941d;
  transition: all 0.7s;
  border: none;
  font-size: 20px;
  line-height: 45px;
}
.search-overlay .search-overlay-form form button:hover {
  background-color: #301d44;
  color: #ffffff;
}
.search-overlay.search-overlay-active.search-overlay {
  opacity: 1;
  visibility: visible;
}
.search-overlay.search-overlay-active.search-overlay .search-overlay-layer {
  transform: translateX(0);
}
.search-overlay.search-overlay-active.search-overlay .search-overlay-close {
  opacity: 1;
  visibility: visible;
}
.search-overlay.search-overlay-active.search-overlay .search-overlay-form {
  opacity: 1;
  visibility: visible;
}

/*================================================
Hero Slider Area
=================================================*/
.hero-slider {
  position: relative;
}
.hero-slider .hero-slider-item {
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 830px;
  position: relative;
}
.hero-slider .hero-slider-item::before {
  content: "";
  position: absolute;
  background: #301d44;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
}
.hero-slider .hero-slider-item.item-bg1 {
  background-image: url("assets/img/hero-slider/hero-slider1.jpg");
}
.hero-slider .hero-slider-item.item-bg2 {
  background-image: url("assets/img/hero-slider/hero-slider2.jpg");
}
.hero-slider .hero-slider-item.item-bg3 {
  background-image: url("assets/img/hero-slider/hero-slider3.jpg");
}
.hero-slider .banner-content {
  position: relative;
  max-width: 720px;
}
.hero-slider .banner-content span {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 15px;
}
.hero-slider .banner-content h1 {
  color: #ffffff;
  font-size: 70px;
  margin-bottom: 15px;
}
.hero-slider .banner-content p {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 40px;
  line-height: 1.8;
}
.hero-slider .banner-content .default-btn-two {
  color: #301d44;
  background-color: #ffffff;
  border-color: #ffffff;
}
.hero-slider .banner-content .default-btn-two:hover {
  background-color: #8732de;
  color: #ffffff;
  border-color: #8732de;
}
.hero-slider.owl-theme .owl-nav {
  margin: 0;
}
.hero-slider.owl-theme .owl-nav .owl-prev, .hero-slider.owl-theme .owl-nav .owl-next {
  position: absolute;
  left: 15px;
  height: 50px;
  width: 40px;
  text-align: center;
  top: 50%;
  font-size: 40px;
  line-height: 1;
  color: #f3f2f3;
  margin: -30px 0 0;
}
.hero-slider.owl-theme .owl-nav .owl-prev:hover, .hero-slider.owl-theme .owl-nav .owl-next:hover {
  background-color: #301d44;
}
.hero-slider.owl-theme .owl-nav .owl-next {
  left: auto;
  right: 15px;
}

/*================================================
Hero Slider Two Area
=================================================*/
.hero-slider-two .hero-slider-two-item {
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 850px;
  z-index: 1;
  position: relative;
}
.hero-slider-two .hero-slider-two-item::before {
  content: "";
  position: absolute;
  background: #301d44;
  width: 100%;
  z-index: -1;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
}
.hero-slider-two .hero-slider-two-item.item-bg1 {
  background-image: url("assets/img/hero-slider/hero-slider2.jpg");
}
.hero-slider-two .hero-slider-two-item.item-bg2 {
  background-image: url("assets/img/hero-slider/hero-slider1.jpg");
}
.hero-slider-two .hero-slider-two-item.item-bg3 {
  background-image: url("assets/img/hero-slider/hero-slider3.jpg");
}
.hero-slider-two .hero-slider-two-item .banner-content span {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 10px;
}
.hero-slider-two .hero-slider-two-item .banner-content h1 {
  color: #ffffff;
  font-size: 50px;
  line-height: 1.4;
}
.hero-slider-two .hero-slider-two-item .banner-content p {
  color: #e0e0e1;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 25px;
  max-width: 440px;
}
.hero-slider-two .hero-slider-two-item .banner-content .default-btn-two {
  color: #301d44;
  background-color: #ffffff;
  border-color: #ffffff;
}
.hero-slider-two .hero-slider-two-item .banner-content .default-btn-two:hover {
  background-color: #8732de;
  color: #ffffff;
  border-color: #8732de;
}
.hero-slider-two .hero-slider-two-item .order-form {
  background: #ffffff;
  padding: 40px 30px;
  margin-left: 100px;
  border-radius: 4px;
}
.hero-slider-two .hero-slider-two-item .order-form h2 {
  font-size: 24px;
  margin-bottom: 25px;
  text-align: center;
  line-height: 1;
}
.hero-slider-two .hero-slider-two-item .order-form .form-control {
  height: auto;
  padding: 15px 20px;
  box-shadow: none;
  font-size: 14px;
  border: 1px solid #bdb7c3;
  border-radius: 0;
  margin-bottom: 20px;
}
.hero-slider-two .hero-slider-two-item .order-form .default-btn-one {
  transition: all 0.7s;
}
.hero-slider-two.owl-theme .owl-nav {
  margin: 0;
}
.hero-slider-two.owl-theme .owl-nav .owl-prev, .hero-slider-two.owl-theme .owl-nav .owl-next {
  position: absolute;
  left: 8px;
  top: 50%;
  font-size: 40px;
  color: #f3f2f3;
  margin: 0;
}
.hero-slider-two.owl-theme .owl-nav .owl-prev i, .hero-slider-two.owl-theme .owl-nav .owl-next i {
  position: relative;
  top: 3px;
}
.hero-slider-two.owl-theme .owl-nav .owl-prev:hover, .hero-slider-two.owl-theme .owl-nav .owl-next:hover {
  background: #8732de;
  color: #ffffff;
}
.hero-slider-two.owl-theme .owl-nav .owl-next {
  left: auto;
  right: 8px;
}

/*================================================
Hero Slider Three Area
=================================================*/
.hero-slider-three {
  position: relative;
}
.hero-slider-three .hero-slider-three-item {
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 800px;
  position: relative;
}
.hero-slider-three .hero-slider-three-item::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
}
.hero-slider-three .hero-slider-three-item.item-bg1 {
  background-image: url("assets/img/hero-slider/hero-slider4.jpg");
}
.hero-slider-three .hero-slider-three-item.item-bg2 {
  background-image: url("assets/img/hero-slider/hero-slider2.jpg");
}
.hero-slider-three .hero-slider-three-item.item-bg3 {
  background-image: url("assets/img/hero-slider/hero-slider3.jpg");
}
.hero-slider-three .hero-slider-three-item .slider-three-text {
  position: relative;
  max-width: 900px;
  margin: auto;
}
.hero-slider-three .hero-slider-three-item .slider-three-text span {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 10px;
}
.hero-slider-three .hero-slider-three-item .slider-three-text h1 {
  color: #ffffff;
  font-size: 50px;
  margin-bottom: 15px;
  line-height: 1.3;
}
.hero-slider-three .hero-slider-three-item .slider-three-text p {
  color: #e0e0e1;
  font-size: 18px;
  font-weight: 500;
  margin: 0 auto 25px;
}
.hero-slider-three.owl-theme .owl-nav {
  margin: 0;
}
.hero-slider-three.owl-theme .owl-nav .owl-prev, .hero-slider-three.owl-theme .owl-nav .owl-next {
  position: absolute;
  left: 8px;
  top: 50%;
  font-size: 40px;
  color: #f3f2f3;
  margin: 0;
}
.hero-slider-three.owl-theme .owl-nav .owl-prev i, .hero-slider-three.owl-theme .owl-nav .owl-next i {
  position: relative;
  top: 3px;
}
.hero-slider-three.owl-theme .owl-nav .owl-prev:hover, .hero-slider-three.owl-theme .owl-nav .owl-next:hover {
  background: #8732de;
  color: #ffffff;
}
.hero-slider-three.owl-theme .owl-nav .owl-next {
  left: auto;
  right: 8px;
}
.hero-slider-three.owl-theme .owl-dots {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
}
.hero-slider-three.owl-theme .owl-dots .owl-dot span {
  margin: 0 10px;
  background: #f7941d;
  width: 12px;
  height: 12px;
  position: relative;
}
.hero-slider-three.owl-theme .owl-dots .owl-dot span::before {
  content: "";
  position: absolute;
  background: #f7941d;
  width: 20px;
  height: 20px;
  left: -4px;
  top: -4px;
  line-height: 24px;
  border: 5px solid #ab6d2a;
  border-radius: 50%;
  transform: scale(0);
  transition: all 0.7s;
}
.hero-slider-three.owl-theme .owl-dots .owl-dot span:hover::before {
  transform: scale(1);
}
.hero-slider-three.owl-theme .owl-dots .owl-dot.active span::before {
  transform: scale(1);
}

/*================================================
Hero Contact Area
=================================================*/
.contact-area {
  position: relative;
  z-index: 1;
}
.contact-area.mb-85 {
  bottom: 85px;
}
.contact-area .contact-content {
  background: #8732de;
  border-radius: 5px;
  padding: 50px 30px 20px;
}
.contact-area .contact-content .contact-card {
  padding-left: 55px;
  position: relative;
  margin-bottom: 30px;
}
.contact-area .contact-content .contact-card i {
  background: #ffffff;
  color: #f7941d;
  font-size: 30px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 5px;
  position: absolute;
  left: 0;
  top: 0;
}
.contact-area .contact-content .contact-card h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #ffffff;
}
.contact-area .contact-content .contact-card p {
  font-size: 14px;
  margin-bottom: 0;
  color: #ffffff;
}
.contact-area .contact-content .contact-card p a {
  color: #ffffff;
}

/*================================================
About Area
=================================================*/
.about-area .about-contant {
  margin-top: 30px;
}
.about-area .about-contant .section-title {
  text-align: left;
  margin-bottom: 20px;
  max-width: none;
}
.about-area .about-contant .about-text .mb-20 {
  margin-bottom: 25px;
}
.about-area .about-contant .about-text .btn-bs {
  box-shadow: 1px 20px 20px 0px #e8e8e8;
  margin-top: 20px;
}
.about-area .about-image {
  position: relative;
}
.about-area .about-image img {
  width: 100%;
}
.about-area .col-lg-3 {
  position: relative;
}
.about-area .about-tabs {
  position: absolute;
  width: 360px;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}
.about-area .about-tabs .title {
  font-size: 25px;
  margin-bottom: 15px;
}
.about-area .about-tabs .tab-contant {
  background: #f3fafd;
  padding: 30px 20px;
  box-shadow: 3px 7px 20px 0px #e8e8e8;
  border: 8px solid #ffffff;
  border-radius: 4px;
}
.about-area .about-tabs .tab-contant .nav-tabs {
  border: none;
}
.about-area .about-tabs .tab-contant .nav-tabs .nav-link {
  font-size: 16px;
  font-weight: 500;
  color: #301d44;
  margin-bottom: 20px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 4px solid #ffffff;
  padding: 0;
  margin-right: 20px;
}
.about-area .about-tabs .tab-contant .nav-tabs .nav-link:last-child {
  margin-right: 0;
}
.about-area .about-tabs .tab-contant .nav-tabs .nav-link.active {
  color: #495057;
  background-color: transparent;
  border-bottom: 4px solid #f5c78d;
}
.about-area .about-tabs .tab-contant .vision ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.about-area .about-tabs .tab-contant .vision ul li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 35px;
  line-height: 1.7;
}
.about-area .about-tabs .tab-contant .vision ul li:last-child {
  margin-bottom: 0;
}
.about-area .about-tabs .tab-contant .vision ul li i {
  font-size: 25px;
  position: absolute;
  left: 0;
  color: #f7941d;
}

/*================================================
Services Area
=================================================*/
.services-area {
  background: #301d44;
}
.services-area .section-title h2 {
  color: #ffffff;
}
.services-area .view-btn {
  text-align: center;
}
.services-area .view-btn .default-btn {
  border: 1px solid #f7941d;
  color: #f7941d;
  padding: 15px 30px;
  display: inline-block;
}
.services-area .view-btn .default-btn:hover {
  border: 1px solid #f7941d;
  background: #f7941d;
  color: #ffffff;
}
.services-area.services-two {
  background-image: url("assets/img/services/service-bg1.jpg");
  background-position: center center;
  background-size: cover;
  position: relative;
  padding-top: 195px;
}
.services-area.services-two::before {
  content: "";
  position: absolute;
  background: #301d44;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
}
.services-area.services-two .section-title {
  position: relative;
}
.services-area.services-two .services-slider .service-card {
  margin: 0;
}
.services-area.services-two .services-slider .service-card i {
  background: #f2e5ff;
  color: #8732de;
}
.services-area.services-two .services-slider .service-card:hover i {
  background: #8732de;
  color: #ffffff;
}
.services-area.services-two .services-slider.owl-theme .owl-nav {
  margin: 0;
}
.services-area.services-two .services-slider.owl-theme .owl-nav .owl-prev, .services-area.services-two .services-slider.owl-theme .owl-nav .owl-next {
  position: absolute;
  margin: 0;
  left: -60px;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid #f7941d;
  color: #f7941d;
  width: 40px;
  height: 40px;
  font-size: 30px;
  line-height: 45px;
  border-radius: 50%;
  transition: all 0.7s;
}
.services-area.services-two .services-slider.owl-theme .owl-nav .owl-prev:hover, .services-area.services-two .services-slider.owl-theme .owl-nav .owl-next:hover {
  background-color: #f7941d;
  color: #ffffff;
}
.services-area.services-two .services-slider.owl-theme .owl-nav .owl-next {
  left: auto;
  right: -60px;
}

.service-card {
  text-align: center;
  background: #ffffff;
  padding: 35px 15px;
  border-radius: 4px;
  margin-bottom: 30px;
}
.service-card i {
  color: #ffffff;
  background: #8732de;
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 50%;
  font-size: 60px;
  margin-bottom: 25px;
  transition: all 0.7s;
}
.service-card h3 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 12px;
}
.service-card p {
  font-size: 14px;
  margin: 0;
}
.service-card .default-btn-two {
  border-color: #301d44;
  color: #301d44;
  border-radius: 4px;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: transparent;
}
.service-card:hover i {
  background: #301d44;
  color: #f7941d;
}
.service-card:hover .default-btn-two {
  background: #f7941d;
  color: #ffffff;
  border-color: #f7941d;
}

/*================================================
Choose Area
=================================================*/
.choose-area {
  position: relative;
}
.choose-area .choose-contant {
  padding: 15px 20px;
  margin-bottom: 30px;
  box-shadow: 0px -1px 20px 0px #f3efef;
  border-radius: 5px;
}
.choose-area .choose-contant:last-child {
  margin-bottom: 0;
}
.choose-area .choose-contant .choose-card {
  padding-left: 100px;
  position: relative;
}
.choose-area .choose-contant .choose-card i {
  background: #8732de;
  color: #ffffff;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 50px;
  border-radius: 10px;
  position: absolute;
  left: 0;
}
.choose-area .choose-contant .choose-card h3 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
}
.choose-area .choose-contant .choose-card p {
  font-size: 14px;
}
.choose-area .choose-text {
  position: relative;
  padding-left: 20px;
}
.choose-area .choose-text .section-title {
  text-align: left;
  margin-bottom: 20px;
}
.choose-area .choose-text .default-btn-one {
  box-shadow: 0px -1px 20px 0px #f3efef;
  margin-top: 20px;
}
.choose-area .choose-text .shape-image {
  position: absolute;
  right: 0;
  bottom: -75px;
}
.choose-area .choose-text .shape-image img {
  opacity: 0.2;
}

/*================================================
Speciality Area
=================================================*/
.speciality-area {
  background: #f5f5f5;
}
.speciality-area .speciality-slider {
  position: relative;
}
.speciality-area .speciality-slider.owl-theme .owl-nav {
  margin: 0;
}
.speciality-area .speciality-slider.owl-theme .owl-nav .owl-prev, .speciality-area .speciality-slider.owl-theme .owl-nav .owl-next {
  position: absolute;
  margin: 0;
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid #f7941d;
  color: #f7941d;
  width: 40px;
  height: 40px;
  font-size: 30px;
  line-height: 45px;
  border-radius: 50%;
  transition: all 0.7s;
}
.speciality-area .speciality-slider.owl-theme .owl-nav .owl-prev:hover, .speciality-area .speciality-slider.owl-theme .owl-nav .owl-next:hover {
  background-color: #f7941d;
  color: #ffffff;
}
.speciality-area .speciality-slider.owl-theme .owl-nav .owl-next {
  left: auto;
  right: -50px;
}

.speciality-card .speciality-text {
  background: #ffffff;
  padding: 30px;
}
.speciality-card .speciality-text h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.speciality-card .speciality-text h3 a {
  display: inline-block;
  color: #301d44;
}
.speciality-card .speciality-text h3 a:hover {
  color: #f7941d;
}

/*================================================
Testimonials Area
=================================================*/
.testimonials-area {
  position: relative;
}
.testimonials-area .testimonials-slider {
  position: relative;
}
.testimonials-area .testimonials-slider .testimonials-card {
  text-align: center;
  background: #301d44;
  padding: 70px 20px 80px;
}
.testimonials-area .testimonials-slider .testimonials-card .client-img {
  margin-bottom: 15px;
}
.testimonials-area .testimonials-slider .testimonials-card .client-img img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto 15px;
}
.testimonials-area .testimonials-slider .testimonials-card .client-img h3 {
  font-size: 20px;
  margin-bottom: 3px;
  color: #ffffff;
}
.testimonials-area .testimonials-slider .testimonials-card .client-img span {
  font-weight: 300;
  font-size: 12px;
  display: inline-block;
  color: #c8c5cb;
}
.testimonials-area .testimonials-slider .testimonials-card .rating {
  margin-bottom: 15px;
}
.testimonials-area .testimonials-slider .testimonials-card .rating i {
  font-size: 18px;
  color: #f7941d;
}
.testimonials-area .testimonials-slider .testimonials-card .feedback-text {
  max-width: 800px;
  margin: auto;
}
.testimonials-area .testimonials-slider .testimonials-card p {
  color: #e0dfe2;
  font-size: 18px;
  font-weight: 500;
}
.testimonials-area .testimonials-slider.owl-theme .owl-nav {
  margin: 0;
}
.testimonials-area .testimonials-slider.owl-theme .owl-nav .owl-prev, .testimonials-area .testimonials-slider.owl-theme .owl-nav .owl-next {
  position: absolute;
  margin: 0;
  left: -60px;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid #f7941d;
  color: #f7941d;
  width: 40px;
  height: 40px;
  font-size: 30px;
  line-height: 45px;
  border-radius: 50%;
  transition: all 0.7s;
}
.testimonials-area .testimonials-slider.owl-theme .owl-nav .owl-prev:hover, .testimonials-area .testimonials-slider.owl-theme .owl-nav .owl-next:hover {
  background-color: #f7941d;
  color: #ffffff;
}
.testimonials-area .testimonials-slider.owl-theme .owl-nav .owl-next {
  left: auto;
  right: -60px;
}
.testimonials-area .testimonials-slider.owl-theme .owl-dots {
  margin-top: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
}
.testimonials-area .testimonials-slider.owl-theme .owl-dots .owl-dot span {
  width: 20px;
  height: 20px;
  margin: 0 5px;
  background: #f7941d;
  border-radius: 50%;
  border: 5px solid #301d44;
}
.testimonials-area .testimonials-slider.owl-theme .owl-dots .owl-dot span:hover {
  background: #f7941d !important;
  border: 5px solid #fabf77 !important;
}
.testimonials-area .testimonials-slider.owl-theme .owl-dots .owl-dot.active span {
  background: #f7941d !important;
  border: 5px solid #fabf77 !important;
}

/*================================================
 partner Slider Area
=================================================*/
.partner-area .partner-slider-item img {
  width: auto;
  margin: auto;
}

/*================================================
Transportation Area
=================================================*/
.transportation-area {
  background: #8732de;
  position: relative;
}
.transportation-area .transportation-form {
  max-width: 570px;
  margin-left: auto;
  padding-right: 100px;
}
.transportation-area .transportation-form .section-title {
  text-align: left;
  margin-bottom: 20px;
}
.transportation-area .transportation-form .section-title span {
  color: #ffffff;
}
.transportation-area .transportation-form .section-title h2 {
  color: #ffffff;
  font-size: 30px;
}
.transportation-area .transportation-form .transportation-shape {
  position: absolute;
  top: 40px;
  left: 5px;
}
.transportation-area .transportation-form .form-control {
  height: auto;
  padding: 15px 0;
  background-color: transparent;
  border-bottom: 1px solid #b17aea;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  box-shadow: none;
  color: #ffffff;
  font-size: 14.5px;
}
.transportation-area .transportation-form .form-control:hover {
  border-bottom: 1px solid #f7941d;
}
.transportation-area .transportation-form .form-control::-webkit-input-placeholder {
  color: #ffffff;
}
.transportation-area .transportation-form .form-control:-ms-input-placeholder {
  color: #ffffff;
}
.transportation-area .transportation-form .form-control::placeholder {
  color: #ffffff;
  transition: all 0.7s;
}
.transportation-area .transportation-form .form-control:focus::placeholder {
  color: transparent;
}
.transportation-area .transportation-form .mb-30 {
  margin-bottom: 45px;
}
.transportation-area .transportation-form .form-btn {
  margin-bottom: 40px;
}
.transportation-area .transportation-form .form-btn .default-btn-one {
  border: none;
  transition: all 0.7s;
}
.transportation-area .transportation-form .form-btn .default-btn-one:hover {
  background: #301d44;
}
.transportation-area .transportation-form .form-btn .default-btn-two {
  background: none;
  transition: all 0.7s;
  border-color: #ffffff;
}
.transportation-area .transportation-form .form-btn .default-btn-two:hover {
  border-color: #301d44;
  background: #301d44;
}
.transportation-area .transportation-form h3 {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}
.transportation-area .transportation-form h3 span {
  color: #f21860;
}

.popup-video {
  background-size: cover;
  background-position: center center;
  position: relative;
  height: 100%;
}
.popup-video::before {
  background: #301d44;
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0.6;
  position: absolute;
}
.popup-video.video-bg {
  background-image: url("assets/img/popup/video-bg.jpg");
}
.popup-video .video-btn {
  position: relative;
  text-align: center;
}
.popup-video .video-btn a {
  background: #ffffff;
  color: #f7941d;
  height: 90px;
  width: 90px;
  position: absolute;
  text-align: center;
  margin: -50px 0 0 -30px;
  border-radius: 100px;
  z-index: 1;
  font-size: 65px;
  padding-left: 8px;
  line-height: 90px;
}
.popup-video .video-btn a i {
  position: relative;
  top: 7px;
}
.popup-video .video-btn a .ripple {
  background: #ffffff;
  position: absolute;
  width: 150px;
  height: 150px;
  z-index: -1;
  left: 50%;
  top: 50%;
  opacity: 0;
  margin: -75px 0 0 -75px;
  border-radius: 100px;
  animation: ripple 1.8s infinite;
}
.popup-video .video-btn a .ripple:nth-child(2) {
  animation-delay: 0.3s;
}
.popup-video .video-btn a .ripple:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes ripple {
  0% {
    opacity: 1;
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
/*================================================
Footer Area
=================================================*/
.footer-area {
  background: #f5f5f5;
}
.footer-area .footer-widget {
  margin-bottom: 30px;
}
.footer-area .footer-widget .logo {
  margin-bottom: 20px;
}
.footer-area .footer-widget p {
  color: #7a7a7a;
}
.footer-area .footer-widget h3 {
  font-size: 20px;
  color: #301d44;
  margin-bottom: 25px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
.footer-area .footer-widget .footer-text {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.footer-area .footer-widget .footer-text li {
  color: #d3d3d3;
  margin-bottom: 10px;
  transition: all 0.7s;
}
.footer-area .footer-widget .footer-text li:last-child {
  margin-bottom: 0;
}
.footer-area .footer-widget .footer-text li a {
  color: #7a7a7a;
}
.footer-area .footer-widget .footer-text li a:hover {
  color: #f7941d;
  padding-left: 5px;
}
.footer-area .footer-widget.pl-80 {
  padding-left: 80px;
}
.footer-area .footer-widget.pl-50 {
  padding-left: 50px;
}
.footer-area .footer-widget .info-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.footer-area .footer-widget .info-list li {
  padding-left: 35px;
  position: relative;
  margin-bottom: 15px;
}
.footer-area .footer-widget .info-list li a {
  color: #7a7a7a;
}
.footer-area .footer-widget .info-list li a:hover {
  color: #f7941d;
  padding-left: 10px;
}
.footer-area .footer-widget .info-list li i {
  color: #f7941d;
  font-size: 16px;
  position: absolute;
  left: 0;
  top: -2px;
  background: #ffffff;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 4px;
  transition: all 0.7s;
  box-shadow: 0px 0px 20px 0px #d8d8d8;
}
.footer-area .footer-widget .info-list li i:hover {
  background: #f7941d;
  color: #ffffff;
}
.footer-area .footer-widget .info-list li span {
  display: block;
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 5px;
}
.footer-area .footer-socials {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.footer-area .footer-socials li {
  display: inline-block;
  padding: 0 !important;
  margin: 0 10px 0 0;
}
.footer-area .footer-socials li:last-child {
  margin: 0;
}
.footer-area .footer-socials li a i {
  display: inline-block;
  background: #ffffff;
  color: #301d44;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 5px;
  font-size: 20px;
  position: relative;
  margin-top: 10px;
  transition: all 0.7s;
}
.footer-area .footer-socials li a i:hover {
  background: #f7941d;
  color: #ffffff;
}
.footer-area.footer-two {
  padding-top: 170px;
}

/*================================================
footer Bottom Area
=================================================*/
.footer-bottom {
  background: #301d44;
}
.footer-bottom p {
  text-align: center;
  font-size: 15px;
  padding: 20px 0;
  color: #ffffff;
  margin: 0;
}
.footer-bottom p a {
  color: #f7941d;
}

/*================================================
Preloader Area
=================================================*/
.preloader {
  position: fixed;
  z-index: 99999999;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  left: 0;
}
.preloader .lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.preloader .lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #f7941d;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #f7941d transparent transparent transparent;
}
.preloader .lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.preloader .lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.preloader .lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*================================================
Preloader Area
=================================================*/
.go-top {
  position: fixed;
  bottom: 15px;
  right: 15px;
  cursor: pointer;
  z-index: 9999;
  transform: scale(0);
}
.go-top.active {
  transform: scale(1);
}
.go-top i {
  background: #f21860;
  color: #ffffff;
  width: 35px;
  height: 35px;
  display: inline-block;
  line-height: 35px;
  text-align: center;
  font-size: 25px;
  border-radius: 50px;
  transition: all 0.7s;
}
.go-top i:hover {
  background: #f7941d;
  color: #ffffff;
}

/*================================================
Freight Area
=================================================*/
.freight-area {
  background: #f8f9fb;
}
.freight-area.freight-area-two .section-title {
  text-align: left;
  margin-left: 0;
}
.freight-area.freight-area-two .services-slider-two.owl-theme .owl-nav {
  margin: 0;
}
.freight-area.freight-area-two .services-slider-two.owl-theme .owl-nav .owl-prev, .freight-area.freight-area-two .services-slider-two.owl-theme .owl-nav .owl-next {
  position: absolute;
  right: 45px;
  top: -95px;
  border: 1px solid #f7941d;
  color: #f7941d;
  width: 35px;
  height: 35px;
  line-height: 40px;
  font-size: 30px;
  border-radius: 50%;
  margin: 0;
  transition: all 0.7s;
}
.freight-area.freight-area-two .services-slider-two.owl-theme .owl-nav .owl-prev:hover, .freight-area.freight-area-two .services-slider-two.owl-theme .owl-nav .owl-next:hover {
  background-color: #f7941d;
  color: #ffffff;
}
.freight-area.freight-area-two .services-slider-two.owl-theme .owl-nav .owl-next {
  right: 0;
}

.freight-inner {
  margin-top: -50px;
  position: relative;
  z-index: 1;
}

.service-card-two {
  margin-bottom: 30px;
  text-align: center;
}
.service-card-two img {
  border-radius: 4px;
}
.service-card-two .service-caption {
  padding: 30px 20px;
  background: #ffffff;
}
.service-card-two .service-caption h3 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 15px;
}
.service-card-two .service-caption p {
  margin-bottom: 20px;
  min-height: 50px;
}
.service-card-two .service-caption .default-btn-two {
  padding: 10px 20px;
  border-radius: 4px;
  color: #ffffff;
  border-color: #301d44;
  background-color: #8732de;
}
.service-card-two .service-caption .default-btn-two:hover {
  background: #f7941d;
  color: #ffffff;
  border-color: #f7941d;
}

/*================================================
About Two Area
=================================================*/
.about-two-img img {
  border-radius: 5px;
}

.about-contant-others .section-title {
  text-align: left;
  margin-bottom: 15px;
}
.about-contant-others .about-two-text {
  position: relative;
}
.about-contant-others .about-two-text ul {
  padding: 0;
  list-style-type: none;
  margin: 15px 0 30px;
}
.about-contant-others .about-two-text ul li {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  position: relative;
  padding-left: 30px;
}
.about-contant-others .about-two-text ul li i {
  position: absolute;
  background: #f7941d;
  color: #ffffff;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  left: 0;
}
.about-contant-others .about-two-text .watch-video {
  position: absolute;
  bottom: 0;
  left: 175px;
}
.about-contant-others .about-two-text .watch-video .video-btn a {
  color: #f21860;
  display: inline-block;
  padding-bottom: 8px;
}
.about-contant-others .about-two-text .watch-video .video-btn a i {
  background: transparent;
  color: #f21860;
  width: 55px;
  height: 55px;
  font-size: 30px;
  line-height: 55px;
  text-align: center;
  border: 1px solid #f21860;
  border-radius: 50%;
  position: relative;
  margin-right: 10px;
  top: 8px;
  padding-left: 2px;
  transition: all 0.7s;
}
.about-contant-others .about-two-text .watch-video .video-btn a:hover {
  color: #f7941d;
}
.about-contant-others .about-two-text .watch-video .video-btn a:hover i {
  background: #f7941d;
  color: #ffffff;
  border: 1px solid #f7941d;
}

/*================================================
Counter Area
=================================================*/
.counter-area {
  background: #321e43;
  background-position: center center;
  background-size: cover;
  position: relative;
  top: 100px;
  z-index: 1;
}
.counter-area::before {
  content: "";
  position: absolute;
  background-image: url("assets/img/counter-bg1.png");
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.counter-area .counter-contant {
  max-width: 950px;
  margin: auto;
  padding: 65px 0 35px;
}
.counter-area .counter-contant .counter-card {
  padding-left: 80px;
  position: relative;
  margin-bottom: 30px;
}
.counter-area .counter-contant .counter-card i {
  position: absolute;
  left: 0;
  color: #f7941d;
  font-size: 70px;
}
.counter-area .counter-contant .counter-card h3 {
  color: #ffffff;
  font-size: 36px;
  margin-bottom: 5px;
}
.counter-area .counter-contant .counter-card p {
  color: #ffffff;
}

/*================================================
Choos Us Two Area
=================================================*/
.choose-us-two {
  position: relative;
  z-index: 5;
  background: #f8f9fb;
}
.choose-us-two .choose-content {
  padding: 60px 0;
}
.choose-us-two .choose-content .choose-us-text .section-title {
  text-align: left;
  margin-bottom: 20px;
}
.choose-us-two .choose-content ul {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 30px;
}
.choose-us-two .choose-content ul li {
  flex: 0 0 50%;
  max-width: 50%;
  margin-top: 15px;
  padding-left: 30px;
  padding-right: 10px;
  position: relative;
}
.choose-us-two .choose-content ul li i {
  position: absolute;
  background: #f7941d;
  color: #ffffff;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  left: 0;
}
.choose-us-two .choose-us-image {
  text-align: center;
}
.choose-us-two .choose-us-image img {
  border-radius: 5px;
}

/*================================================
Personal Data Area
=================================================*/
.personal-data-area .safely-image {
  position: relative;
}
.personal-data-area .safely-image .caption {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(247, 148, 29, 0.95);
  width: 70%;
  height: 100%;
  padding: 30px;
  transition: all 0.7s;
}
.personal-data-area .safely-image .caption h3 {
  font-size: 26px;
  margin-bottom: 25px;
  color: #ffffff;
  z-index: 1;
}
.personal-data-area .safely-image .caption p {
  color: #fce7d7;
  z-index: 1;
}
.personal-data-area .safely-image:hover .caption {
  width: 80%;
}
.personal-data-area .personal-data-form h2 {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 15px;
}
.personal-data-area .personal-data-form .form-control {
  display: block;
  width: 100%;
  height: auto;
  padding: 15px 25px;
  margin-bottom: 25px;
  box-shadow: none;
  font-size: 14px;
  border: 1px solid #988ea1;
  border-radius: 0;
}
.personal-data-area .personal-data-form .form-control.control-one {
  margin-right: 2px;
}
.personal-data-area .personal-data-form .btn {
  width: 100%;
  margin-bottom: 30px;
}
.personal-data-area .personal-data-form .btn-primary {
  color: #ffffff;
  background-color: #301d44;
  border: 0;
  box-shadow: none;
  padding: 15px 0;
  border-radius: 0;
  transition: all 0.7s;
}
.personal-data-area .personal-data-form .btn-primary:hover {
  background: #f7941d;
  color: #ffffff;
}
.personal-data-area .personal-data-form p {
  font-size: 16;
  font-weight: 500;
  color: #301d44;
}
.personal-data-area .personal-data-form p span {
  color: #f7941d;
}
.personal-data-area .personal-data-form p a {
  color: #f21860;
  font-size: 18px;
  font-weight: 600;
}

/*================================================
Feedback Area
=================================================*/
.feedback-area {
  position: relative;
  background-color: #f6f7fa;
}
.feedback-area .feedback-slider {
  position: relative;
}
.feedback-area .feedback-slider .feedback-slider-item {
  position: relative;
  text-align: center;
  background: #ffffff;
  padding: 70px 20px;
  max-width: 900px;
  margin: auto;
}
.feedback-area .feedback-slider .feedback-slider-item .feedback-img {
  margin-bottom: 15px;
}
.feedback-area .feedback-slider .feedback-slider-item .feedback-img img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto 15px;
}
.feedback-area .feedback-slider .feedback-slider-item .feedback-img h3 {
  font-size: 20px;
  margin-bottom: 3px;
  color: #301d44;
}
.feedback-area .feedback-slider .feedback-slider-item .feedback-img span {
  font-weight: 300;
  font-size: 12px;
  display: inline-block;
  color: #301d44;
}
.feedback-area .feedback-slider .feedback-slider-item .rating {
  margin-bottom: 15px;
}
.feedback-area .feedback-slider .feedback-slider-item .rating i {
  font-size: 18px;
  color: #f7941d;
}
.feedback-area .feedback-slider .feedback-slider-item .feedback-text {
  max-width: 800px;
  margin: auto;
}
.feedback-area .feedback-slider .feedback-slider-item p {
  color: #301d44;
  font-size: 18px;
  font-weight: 500;
}
.feedback-area .feedback-slider .feedback-slider-item .feedback-icon-one {
  position: absolute;
  left: 40px;
  top: 30px;
  font-size: 90px;
  line-height: 1;
}
.feedback-area .feedback-slider .feedback-slider-item .feedback-icon-one i {
  color: #f4f3f5;
}
.feedback-area .feedback-slider .feedback-slider-item .feedback-icon-two {
  position: absolute;
  right: 40px;
  bottom: 0;
  font-size: 90px;
  line-height: 1;
}
.feedback-area .feedback-slider .feedback-slider-item .feedback-icon-two i {
  color: #f4f3f5;
}
.feedback-area .feedback-slider.owl-theme .owl-nav {
  margin: 0;
}
.feedback-area .feedback-slider.owl-theme .owl-nav .owl-prev, .feedback-area .feedback-slider.owl-theme .owl-nav .owl-next {
  position: absolute;
  margin: 0;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid #f7941d;
  color: #f7941d;
  width: 40px;
  height: 40px;
  font-size: 30px;
  line-height: 45px;
  border-radius: 50%;
  transition: all 0.7s;
}
.feedback-area .feedback-slider.owl-theme .owl-nav .owl-prev:hover, .feedback-area .feedback-slider.owl-theme .owl-nav .owl-next:hover {
  background-color: #f7941d;
  color: #ffffff;
}
.feedback-area .feedback-slider.owl-theme .owl-nav .owl-next {
  left: auto;
  right: 0;
}

/*================================================
Blog Area
=================================================*/
.blog-card {
  box-shadow: 1px 1px 20px 0px #f2e5ff;
  margin-bottom: 30px;
  position: relative;
  text-align: center;
}
.blog-card .blog-image {
  border-radius: 4px;
}
.blog-card .blog-text {
  padding: 20px;
}
.blog-card .blog-text .meta-tag {
  position: absolute;
  top: 0;
  background: #f7941d;
  color: #ffffff;
  left: 0;
  padding: 3px 15px;
  font-size: 13px;
}
.blog-card .blog-text .date {
  margin-bottom: 7px;
  font-size: 14px;
}
.blog-card .blog-text h3 {
  font-size: 20px;
  margin-bottom: 10px;
  line-height: 1.4;
}
.blog-card .blog-text h3 a {
  color: #301d44;
}
.blog-card .blog-text h3 a:hover {
  color: #f7941d;
}
.blog-card .blog-text p {
  font-size: 14px;
}
.blog-card .blog-text .mb-20 {
  margin-bottom: 20px;
  font-size: 15px;
}
.blog-card .default-btn-two {
  border-color: #301d44;
  color: white;
  background-color: #8732de;
  padding: 10px 25px;
}
.blog-card .default-btn-two:hover {
  border-color: #f7941d;
  background-color: #f7941d;
  color: #ffffff;
}

.pagination {
  padding: 0;
  display: block;
  margin: 20px 0 0;
  text-align: center;
}
.pagination .page-item {
  display: inline-block;
  margin: 0 3px;
}
.pagination .page-item .page-link {
  color: #f7941d;
  border: none;
  border-radius: 0;
  box-shadow: none;
  background: #f5f5f5;
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  margin: 0;
  border-radius: 50%;
}
.pagination .page-item .page-link:hover {
  background-color: #f7941d;
  color: #ffffff;
  box-shadow: 0px 7px 20px 0px #dcdcdc;
}
.pagination .page-item.active .page-link {
  background-color: #f7941d;
  color: #ffffff;
  box-shadow: 0px 7px 20px 0px #dcdcdc;
}

/*================================================
Newsletter Area
=================================================*/
.newsletter-area {
  position: relative;
}
.newsletter-area::before {
  content: "";
  background: #f5f5f5;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 65px;
}

.newsletter-content {
  background: #8732de;
  padding: 50px 100px;
  border-radius: 5px;
  position: relative;
}
.newsletter-content .newsletter-title h3 {
  font-weight: 600;
  font-size: 22px;
  color: #ffffff;
  margin-bottom: 5px;
}
.newsletter-content .newsletter-title p {
  font-size: 16px;
  color: #ffffff;
}
.newsletter-content .newsletter-form .form-control {
  width: 65%;
  height: auto;
  padding: 15px 25px;
  background-color: transparent;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  color: #ffffff;
  margin-bottom: 0;
  box-shadow: none;
}
.newsletter-content .newsletter-form .form-control::placeholder {
  transition: all 0.7s;
  letter-spacing: 0.5px;
  color: #f1f1f1;
}
.newsletter-content .newsletter-form .form-control:focus::placeholder {
  color: transparent;
}
.newsletter-content .newsletter-form .btn {
  padding: 15px 35px;
  transition: all 0.7s;
  background: #f7941d;
  box-shadow: none;
  border: 0;
  position: absolute;
  top: 0;
  right: 25px;
  opacity: 1;
}
.newsletter-content .newsletter-form .btn:hover {
  background: #f21860;
  color: #ffffff;
}

/*================================================
Safe Area
=================================================*/
.safe-area .about-contant-others .about-two-text .mb-30 {
  margin-bottom: 30px;
}

/*================================================
Shipmante Area
=================================================*/
.shipmante-area {
  background-size: cover;
  background-position: center center;
  position: relative;
  padding-top: 160px;
  padding-bottom: 160px;
}
.shipmante-area::before {
  content: "";
  background: #301d44;
  height: 100%;
  width: 50%;
  border-radius: 5px;
  left: 0;
  top: 0;
  opacity: 0.6;
  position: absolute;
}
.shipmante-area.shipmante-area-bg {
  background-image: url("assets/img/shipmante-bg1.jpg");
}
.shipmante-area .shipmante-text {
  position: relative;
}
.shipmante-area .shipmante-text h2 {
  font-size: 70px;
  color: #ffffff;
  margin-bottom: 15px;
}
.shipmante-area .shipmante-text p {
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 25px;
}
.shipmante-area .shipmante-text a {
  color: #f7941d;
  border-bottom: 1px solid #f7941d;
}
.shipmante-area .shipmante-btn {
  text-align: center;
}
.shipmante-area .shipmante-btn a i {
  background: #301d44;
  color: #ffffff;
  height: 100px;
  width: 100px;
  position: absolute;
  text-align: center;
  margin: -50px 0 0 -50px;
  border-radius: 50%;
  z-index: 1;
  font-size: 50px;
  padding-left: 2px;
  line-height: 100px;
}
.shipmante-area .shipmante-btn a .ripple {
  background: #ffffff;
  position: absolute;
  width: 200px;
  height: 200px;
  left: 50%;
  top: 50%;
  opacity: 0;
  margin: -100px 0 0 -100px;
  border-radius: 100px;
  -webkit-animation: ripple 1.8s infinite;
  animation: ripple 1.8s infinite;
}
.shipmante-area .shipmante-btn a .ripple:nth-child(2) {
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
}
.shipmante-area .shipmante-btn a .ripple:nth-child(3) {
  animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
}

@keyframes ripple {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
/*================================================
Clients Area
=================================================*/
.clients-area {
  background-color: #f7f8fa;
}
.clients-area .section-title {
  text-align: left;
  margin-left: 0;
}
.clients-area .clients-slider {
  position: relative;
}
.clients-area .clients-slider .clients-slider-item {
  background-color: #ffffff;
  padding: 50px;
  position: relative;
  margin-left: 70px;
}
.clients-area .clients-slider .clients-slider-item .quote-icon {
  position: absolute;
  font-size: 80px;
  right: 70px;
  top: 35px;
  color: #f4f3f5;
}
.clients-area .clients-slider .clients-slider-item .item-contant {
  padding-left: 20px;
}
.clients-area .clients-slider .clients-slider-item .item-contant .clients-image {
  position: absolute;
  left: -50px;
}
.clients-area .clients-slider .clients-slider-item .item-contant .clients-image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #8732de;
}
.clients-area .clients-slider .clients-slider-item .item-contant h3 {
  font-size: 20px;
}
.clients-area .clients-slider .clients-slider-item .item-contant span {
  font-weight: 300;
  font-size: 12px;
  display: inline-block;
  margin-bottom: 10px;
}
.clients-area .clients-slider .clients-slider-item .item-contant .rating {
  color: #f7941d;
  font-size: 18px;
  margin-bottom: 5px;
}
.clients-area .clients-slider .clients-slider-item .item-contant p {
  color: #301d44;
  font-weight: 500;
  font-size: 17px;
  font-style: italic;
}
.clients-area .clients-slider.owl-theme .owl-nav {
  margin: 0;
}
.clients-area .clients-slider.owl-theme .owl-nav .owl-prev, .clients-area .clients-slider.owl-theme .owl-nav .owl-next {
  position: absolute;
  right: 45px;
  top: -95px;
  border: 1px solid #f7941d;
  color: #f7941d;
  width: 35px;
  height: 35px;
  line-height: 40px;
  font-size: 30px;
  border-radius: 50%;
  margin: 0;
  transition: all 0.7s;
}
.clients-area .clients-slider.owl-theme .owl-nav .owl-prev:hover, .clients-area .clients-slider.owl-theme .owl-nav .owl-next:hover {
  background-color: #f7941d;
  color: #ffffff;
}
.clients-area .clients-slider.owl-theme .owl-nav .owl-next {
  right: 0;
}

/*================================================
Shipping Area
=================================================*/
.shipping-area {
  position: relative;
}
.shipping-area .shipping-img {
  position: relative;
  padding-left: 40px;
  padding-right: 30px;
}
.shipping-area .shipping-img img {
  border-radius: 5px;
}
.shipping-area .shipping-img::before {
  content: "";
  position: absolute;
  border: 4px solid #f7941d;
  border-radius: 5px;
  width: 70%;
  height: 100%;
  top: 40px;
  left: 0;
}
.shipping-area .shipping-text .shipping-title {
  width: 400px;
}
.shipping-area .shipping-text .shipping-title h2 {
  font-size: 30px;
  margin-bottom: 20px;
}
.shipping-area .shipping-text .shipping-card {
  margin-top: 30px;
}

.shipping-card {
  box-shadow: 0px 0px 20px #eee;
  background: #ffffff;
  border-radius: 5px;
  padding: 30px 75px;
}
.shipping-card .shipping-contant {
  position: relative;
  padding-left: 130px;
}
.shipping-card .shipping-contant .shipping-sign {
  position: absolute;
  right: 15px;
  top: 3px;
}
.shipping-card .shipping-contant .shipping-image {
  position: absolute;
  left: 0;
}
.shipping-card .shipping-contant h3 {
  font-size: 18px;
  margin-bottom: 5px;
}
.shipping-card .shipping-contant span {
  font-weight: 300;
  font-size: 13px;
  display: inline-block;
  margin-bottom: 10px;
}

/*================================================
Frequently Area
=================================================*/
.frequently-area {
  background: #f8f9fb;
}
.frequently-area .frequently-accrodion {
  /* Accordion */
}
.frequently-area .frequently-accrodion h3 {
  font-size: 38px;
  margin-bottom: 30px;
}
.frequently-area .frequently-accrodion p {
  margin-bottom: 30px;
}
.frequently-area .frequently-accrodion p a {
  color: #f21860;
}
.frequently-area .frequently-accrodion p a:hover {
  color: #f7941d;
}
.frequently-area .frequently-accrodion #accordion {
  margin: 20px 0;
}
.frequently-area .frequently-accrodion #accordion .accrodion-drawer {
  background-color: #eef2f5;
  border-radius: 5px;
  margin-bottom: 15px;
}
.frequently-area .frequently-accrodion #accordion .accrodion-drawer h3 {
  padding: 25px;
  cursor: pointer;
  clear: right;
  margin: 0;
  position: relative;
  color: #4d425d;
  font-weight: 500;
  font-size: 17px;
}
.frequently-area .frequently-accrodion #accordion .accrodion-drawer h3 .bx {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 25px;
  font-size: 20px;
  transition: all 0.2s ease;
  background-color: #ffffff;
  border-radius: 50%;
}
.frequently-area .frequently-accrodion #accordion .accrodion-drawer h3 .bx.is-moveable {
  transform: rotate(90deg);
}
.frequently-area .frequently-accrodion #accordion .accrodion-drawer + div {
  font-size: 16px;
}
.frequently-area .frequently-accrodion #accordion .accrodion-drawer .drawer {
  max-height: 100vh;
  overflow-y: auto;
  transition: all 0.5s ease;
  padding: 20px 20px;
  display: block;
  border-top: 1px solid #e0e0e0;
}
.frequently-area .frequently-accrodion #accordion .accrodion-drawer .drawer.is-hidden {
  display: none;
}
.frequently-area .frequently-accrodion #accordion .accrodion-drawer p {
  margin: 0;
  font-size: 15px;
}
.frequently-area .frequently-accrodion #accordion .accrodion-drawer .is-hidden {
  max-height: 0 !important;
}
.frequently-area .frequently-accrodion #accordion .accrodion-drawer .is-rotate {
  transform: rotate(90deg);
}
.frequently-area .frequently-image {
  background-image: url("assets/img/safely/safely1.jpg");
  background-repeat: no-repeat;
  margin-right: 145px;
  position: relative;
}
.frequently-area .frequently-image::before {
  content: "";
  position: absolute;
  background: #301d44;
  width: 70%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
}
.frequently-area .frequently-image .frequently-text {
  max-width: 290px;
  text-align: center;
  padding: 90px 25px;
  position: relative;
}
.frequently-area .frequently-image .frequently-text h3 {
  font-size: 22px;
  margin-bottom: 10px;
  color: #ffffff;
  line-height: 1.5;
}
.frequently-area .frequently-image .frequently-text p {
  margin-bottom: 20px;
  color: #d7d7db;
}
.frequently-area .frequently-image .frequently-text a {
  font-weight: 600;
  font-size: 24px;
  border: 1px solid;
  padding: 15px 20px;
  border-radius: 5px;
  display: inline-block;
  color: #ffffff;
}
.frequently-area .frequently-image .frequently-text a:hover {
  background: #ffffff;
  color: #f7941d;
}

/*================================================
Blog Area
=================================================*/
.blog-area .blog-slider .blog-card {
  box-shadow: none;
  margin: 0;
}
.blog-area .blog-slider .blog-card .blog-text {
  border: 1px solid #eee;
}
.blog-area .blog-slider.owl-theme .owl-nav {
  margin: 0;
}
.blog-area .blog-slider.owl-theme .owl-nav .owl-prev, .blog-area .blog-slider.owl-theme .owl-nav .owl-next {
  position: absolute;
  right: 45px;
  top: -95px;
  border: 1px solid #f7941d;
  color: #f7941d;
  width: 35px;
  height: 35px;
  line-height: 40px;
  font-size: 30px;
  border-radius: 50%;
  margin: 0;
  transition: all 0.7s;
}
.blog-area .blog-slider.owl-theme .owl-nav .owl-prev:hover, .blog-area .blog-slider.owl-theme .owl-nav .owl-next:hover {
  background-color: #f7941d;
  color: #ffffff;
}
.blog-area .blog-slider.owl-theme .owl-nav .owl-next {
  right: 0;
}

/*================================================
Page banner Area
=================================================*/
.page-banner {
  background-size: cover;
  background-position: center center;
  height: 450px;
  text-align: center;
}
.page-banner.bg-1 {
  background-image: url("assets/img/page-banner/banner1.jpg");
}
.page-banner.bg-2 {
  background-image: url("assets/img/page-banner/banner2.jpg");
}
.page-banner.bg-3 {
  background-image: url("assets/img/page-banner/banner3.jpg");
}
.page-banner .page-content {
  margin-top: 115px;
  background-color: rgba(48, 29, 68, 0.9);
  max-width: 970px;
  padding: 40px 20px;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
}
.page-banner .page-content h2 {
  color: #ffffff;
  position: relative;
  text-transform: capitalize;
  font-size: 35px;
  margin-bottom: 15px;
}
.page-banner .page-content ul {
  position: relative;
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.page-banner .page-content ul li {
  display: inline-block;
  position: relative;
  font-size: 15px;
  margin-right: 15px;
  text-transform: capitalize;
  color: #ffffff;
}
.page-banner .page-content ul li:last-child {
  margin-right: 0;
}
.page-banner .page-content ul li::before {
  content: "/";
  position: absolute;
  right: -13px;
}
.page-banner .page-content ul li:last-child::before {
  display: none;
}
.page-banner .page-content ul li a {
  color: #ffffff;
}
.page-banner .page-content ul li a:hover {
  color: #f7941d;
}

/*================================================
About Safe Area
=================================================*/
.about-text-area .about-safe-text {
  margin-bottom: 45px;
}
.about-text-area .about-safe-text h2 {
  font-size: 30px;
  margin-bottom: 15px;
  line-height: 1.4;
}
.about-text-area .shipping-card {
  bottom: 0;
  position: relative;
  padding: 0;
  box-shadow: none;
  background: none;
}
.about-text-area .safe-image img {
  border-radius: 5px;
}

.about-info-card {
  margin-bottom: 30px;
}
.about-info-card h3 {
  font-size: 24px;
  padding-bottom: 20px;
  margin-bottom: 25px;
  position: relative;
}
.about-info-card h3::before {
  content: "";
  position: absolute;
  background: #f6b669;
  width: 125px;
  height: 5px;
  bottom: 0;
  text-align: center;
}
.about-info-card ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.about-info-card ul li {
  margin-bottom: 10px;
  padding-left: 30px;
  position: relative;
  line-height: 1.6;
}
.about-info-card ul li:last-child {
  margin-bottom: 0;
}
.about-info-card ul li i {
  position: absolute;
  background: #fff4e5;
  color: #f7941d;
  width: 20px;
  text-align: center;
  height: 20px;
  border-radius: 50%;
  left: 0;
  line-height: 20px;
  top: 2px;
}

/*================================================
Digital AreaArea
=================================================*/
.digital-area {
  background-color: #f8f9fb;
  position: relative;
}
.digital-area .digital-top-contant .digital-image img {
  border-radius: 5px;
}
.digital-area .digital-top-contant .digital-text h2 {
  font-size: 38px;
  margin-bottom: 20px;
  line-height: 1.4;
}
.digital-area .digital-top-contant .digital-text h2 span {
  color: #f7941d;
}
.digital-area .digital-card-contant {
  background-color: #301d44;
  border-radius: 5px;
  padding: 0 40px;
  max-width: 1100px;
  margin-top: 60px;
  position: relative;
}
.digital-area .digital-card-contant::before {
  content: "";
  position: absolute;
  background: #7d7d7d;
  width: 1020px;
  height: 1px;
  top: 50%;
}
.digital-area .digital-card-contant::after {
  content: "";
  position: absolute;
  background: #7d7d7d;
  width: 1px;
  height: 300px;
  top: 15px;
  left: 50%;
}
.digital-area .digital-card-contant .digital-card {
  padding: 40px;
}
.digital-area .digital-card-contant .digital-card .card-text {
  padding-left: 75px;
}
.digital-area .digital-card-contant .digital-card .card-text i {
  color: #ffffff;
  position: absolute;
  left: 55px;
  top: 35px;
  font-size: 50px;
}
.digital-area .digital-card-contant .digital-card .card-text h3 {
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}
.digital-area .digital-card-contant .digital-card .card-text h3 span {
  color: #f7941d;
}
.digital-area .digital-card-contant .digital-card .card-text p {
  color: #d0ced3;
}

/*================================================
Our Services Area
=================================================*/
.our-services-area {
  background-color: #f6f5fa;
}
.our-services-area .service-card-two .freight-text {
  border-radius: 5px;
  box-shadow: 1px 1px 20px 20px #f2e5ff;
}
.our-services-area .service-card-two .freight-text .default-btn-two {
  padding: 10px 20px;
}
.our-services-area .pagination .page-item .page-link {
  background: #ffffff;
}
.our-services-area .pagination .page-item .page-link:hover {
  background: #f7941d;
}
.our-services-area .pagination .page-item.active .page-link {
  background: #f7941d;
}

/*================================================
Best Logistic Area
=================================================*/
.best-logistic-area .logistic-text {
  margin-bottom: 30px;
}
.best-logistic-area .logistic-text h2 {
  font-size: 38px;
  font-weight: 500;
  margin-bottom: 15px;
}
.best-logistic-area .logistic-text h2 span {
  font-weight: bold;
}
.best-logistic-area .logistic-image img {
  border-radius: 5px;
}

/*================================================
Team Area
=================================================*/
.team-area .team-card {
  margin-bottom: 30px;
  overflow: hidden;
  text-align: center;
}
.team-area .team-card .team-image {
  position: relative;
}
.team-area .team-card .team-image img {
  border-radius: 5px;
}
.team-area .team-card .team-image .caption {
  position: absolute;
  bottom: 0;
  background-color: rgba(247, 148, 29, 0.831372549);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  right: 100%;
  transition: all 0.7s;
}
.team-area .team-card .team-image .caption ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  position: absolute;
  bottom: 50px;
  left: 115px;
}
.team-area .team-card .team-image .caption ul li {
  display: inline-block;
  margin: 2px;
}
.team-area .team-card .team-image .caption ul li a i {
  background-color: #ffffff;
  color: #301d44;
  font-size: 18px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 3px;
  transition: all 0.7s;
}
.team-area .team-card .team-image .caption ul li a i:hover {
  background-color: #8732de;
  color: #ffffff;
}
.team-area .team-card .team-text {
  padding-right: 40px;
  padding-top: 30px;
  position: relative;
  min-height: 50px;
}
.team-area .team-card .team-text::before {
  content: "";
  position: absolute;
  background-color: #fbc98e;
  width: 3px;
  height: 110px;
  right: 0;
  top: auto;
  bottom: auto;
  border-radius: 5px;
}
.team-area .team-card .team-text h3 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
}
.team-area .team-card .team-text h3 a {
  color: #301d44;
}
.team-area .team-card .team-text span {
  font-size: 14px;
  margin-bottom: 10px;
  display: inline-block;
}
.team-area .team-card:hover .team-text h3 a {
  color: #8732de;
}
.team-area .team-card:hover .caption {
  right: 0;
}
.team-area .team-card .default-btn-two {
  border-color: #301d44;
  color: #ffffff;
  border-radius: 4px;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #8732de;
  align-items: center;
}
.team-area .team-card:hover i {
  background: #301d44;
  color: #f7941d;
}
.team-area .team-card:hover .default-btn-two {
  background: #f7941d;
  color: #ffffff;
  border-color: #f7941d;
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details-desc {
  background-color: #ffffff;
  padding: 25px;
}
.blog-details-desc .article-content {
  margin-top: 30px;
}
.blog-details-desc .article-content .entry-meta {
  margin-bottom: 15px;
}
.blog-details-desc .article-content .entry-meta ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.blog-details-desc .article-content .entry-meta ul li {
  margin-right: 20px;
  position: relative;
  display: inline-block;
  border-right: 1px solid #eeeeee;
  margin-bottom: 10px;
  padding-right: 20px;
  padding-left: 45px;
}
.blog-details-desc .article-content .entry-meta ul li i {
  font-size: 32px;
  color: #cfcfcf;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.blog-details-desc .article-content .entry-meta ul li span {
  display: block;
  color: #f7941d;
  text-transform: uppercase;
  margin-bottom: 2px;
  font-size: 14px;
}
.blog-details-desc .article-content .entry-meta ul li a {
  color: #301d44;
}
.blog-details-desc .article-content .entry-meta ul li a:hover {
  color: #f7941d;
}
.blog-details-desc .article-content .entry-meta ul li:last-child {
  padding-right: 0;
  margin-right: 0;
  border-right: none;
}
.blog-details-desc .article-content h3 {
  margin-bottom: 15px;
  font-size: 25px;
}
.blog-details-desc .article-content .wp-block-gallery.columns-3 {
  padding-left: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 30px;
  margin-top: 30px;
}
.blog-details-desc .article-content .wp-block-gallery.columns-3 li {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-right: 10px;
  padding-left: 10px;
}
.blog-details-desc .article-content .wp-block-gallery.columns-3 li figure {
  margin-bottom: 0;
}
.blog-details-desc .article-footer {
  background: #f1f1f1;
  display: flex;
  flex-wrap: wrap;
  padding: 15px 20px;
  margin-top: 30px;
}
.blog-details-desc .article-footer .article-tags {
  flex: 0 0 50%;
  max-width: 50%;
  color: #f7941d;
}
.blog-details-desc .article-footer .article-tags span {
  display: inline-block;
  color: #f7941d;
  margin-right: 3px;
  position: relative;
  top: 3px;
  font-size: 20px;
}
.blog-details-desc .article-footer .article-tags a {
  display: inline-block;
  color: #6c6377;
}
.blog-details-desc .article-footer .article-tags a:hover {
  color: #f7941d;
}
.blog-details-desc .article-footer .article-share {
  flex: 0 0 50%;
  max-width: 50%;
}
.blog-details-desc .article-footer .article-share .social {
  padding-left: 0;
  list-style-type: none;
  text-align: right;
  margin-bottom: 0;
}
.blog-details-desc .article-footer .article-share .social li {
  display: inline-block;
}
.blog-details-desc .article-footer .article-share .social li span {
  display: inline-block;
  margin-right: 3px;
  position: relative;
  top: -1px;
}
.blog-details-desc .article-footer .article-share .social li a {
  display: block;
  width: 32px;
  height: 32px;
  line-height: 34px;
  border-radius: 50%;
  background-color: #f7941d;
  color: #ffffff;
  border: 1px solid #f7941d;
  text-align: center;
  font-size: 17px;
  margin-left: 2px;
}
.blog-details-desc .article-footer .article-share .social li a:hover, .blog-details-desc .article-footer .article-share .social li a:focus {
  color: #f7941d;
  background-color: transparent;
}
.blog-details-desc .article-footer .article-share .social li a.facebook {
  background-color: #3b5998;
  border-color: #3b5998;
  color: #ffffff;
}
.blog-details-desc .article-footer .article-share .social li a.facebook:hover, .blog-details-desc .article-footer .article-share .social li a.facebook:focus {
  color: #3b5998;
  background-color: transparent;
}
.blog-details-desc .article-footer .article-share .social li a.twitter {
  background-color: #1da1f2;
  border-color: #1da1f2;
  color: #ffffff;
}
.blog-details-desc .article-footer .article-share .social li a.twitter:hover, .blog-details-desc .article-footer .article-share .social li a.twitter:focus {
  color: #1da1f2;
  background-color: transparent;
}
.blog-details-desc .article-footer .article-share .social li a.linkedin {
  background-color: #007bb5;
  border-color: #007bb5;
  color: #ffffff;
}
.blog-details-desc .article-footer .article-share .social li a.linkedin:hover, .blog-details-desc .article-footer .article-share .social li a.linkedin:focus {
  color: #007bb5;
  background-color: transparent;
}
.blog-details-desc .article-footer .article-share .social li a.instagram {
  background-color: #c13584;
  border-color: #c13584;
  color: #ffffff;
}
.blog-details-desc .article-footer .article-share .social li a.instagram:hover, .blog-details-desc .article-footer .article-share .social li a.instagram:focus {
  color: #c13584;
  background-color: transparent;
}

blockquote, .blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 50px !important;
  position: relative;
  text-align: center;
  z-index: 1;
  margin-bottom: 20px;
  margin-top: 20px;
}
blockquote p, .blockquote p {
  color: #000;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 600;
  font-size: 20px !important;
}
blockquote cite, .blockquote cite {
  display: none;
}
blockquote::before, .blockquote::before {
  color: #efefef;
  position: absolute;
  animation: fade-up 1.5s infinite linear;
  left: 50px;
  top: -50px;
  z-index: -1;
  content: "\ee32";
  font-family: Boxicons;
  font-size: 135px;
}
blockquote::after, .blockquote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #f7941d;
  margin-top: 20px;
  margin-bottom: 20px;
}

.comments-area {
  margin-top: 30px;
}
.comments-area .comments-title {
  line-height: initial;
  margin-bottom: 30px;
  font-size: 20px;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
  font-weight: 600;
}
.comments-area ol, .comments-area ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.comments-area .comment-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.comments-area .children {
  margin-left: 20px;
}
.comments-area .comment-body {
  border-bottom: 1px dashed #eeeeee;
  padding-left: 70px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.comments-area .comment-body .reply {
  margin-top: 15px;
}
.comments-area .comment-body .reply a {
  border: 1px dashed #ded9d9;
  color: #f7941d;
  display: inline-block;
  padding: 6px 20px 3px;
  border-radius: 30px;
  text-transform: uppercase;
  font-size: 13px;
}
.comments-area .comment-body .reply a:hover {
  color: #ffffff;
  background-color: #f7941d;
  border-color: #f7941d;
}
.comments-area .comment-author {
  font-size: 18px;
  margin-bottom: 8px;
  position: relative;
  z-index: 2;
}
.comments-area .comment-author .avatar {
  height: 50px;
  left: -65px;
  position: absolute;
  width: 50px;
  border-radius: 50%;
}
.comments-area .comment-author .fn {
  font-weight: 600;
  font-size: 17px;
  color: #301d44;
}
.comments-area .comment-author .says {
  display: none;
}
.comments-area .comment-metadata {
  margin-bottom: 0.8em;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
}
.comments-area .comment-metadata a {
  display: inline-block;
}
.comments-area .comment-metadata a:hover {
  color: #f7941d;
}
.comments-area .comment-metadata span {
  color: #6c6377;
}
.comments-area .comment-respond {
  margin-top: 30px;
}
.comments-area .comment-respond .comment-reply-title {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 18px;
}
.comments-area .comment-respond .comment-reply-title #cancel-comment-reply-link {
  display: inline-block;
}
.comments-area .comment-respond .comment-form {
  overflow: hidden;
}
.comments-area .comment-respond .comment-notes {
  margin-bottom: 20px;
  margin-top: 10px;
}
.comments-area .comment-respond .comment-notes .required {
  color: red;
}
.comments-area .comment-respond .comment-form-comment {
  float: left;
  width: 100%;
}
.comments-area .comment-respond label {
  display: none;
}
.comments-area .comment-respond input[type=date], .comments-area .comment-respond input[type=time], .comments-area .comment-respond input[type=datetime-local], .comments-area .comment-respond input[type=week], .comments-area .comment-respond input[type=month], .comments-area .comment-respond input[type=text], .comments-area .comment-respond input[type=email], .comments-area .comment-respond input[type=url], .comments-area .comment-respond input[type=password], .comments-area .comment-respond input[type=search], .comments-area .comment-respond input[type=tel], .comments-area .comment-respond input[type=number], .comments-area .comment-respond textarea {
  display: block;
  width: 100%;
  background-color: #f4f4f4;
  border: none;
  padding: 1px 0 0 15px;
  height: 50px;
  outline: 0;
  border-radius: 3px;
  color: #301d44;
  font-size: 14px;
}
.comments-area .comment-respond input[type=date]::placeholder, .comments-area .comment-respond input[type=time]::placeholder, .comments-area .comment-respond input[type=datetime-local]::placeholder, .comments-area .comment-respond input[type=week]::placeholder, .comments-area .comment-respond input[type=month]::placeholder, .comments-area .comment-respond input[type=text]::placeholder, .comments-area .comment-respond input[type=email]::placeholder, .comments-area .comment-respond input[type=url]::placeholder, .comments-area .comment-respond input[type=password]::placeholder, .comments-area .comment-respond input[type=search]::placeholder, .comments-area .comment-respond input[type=tel]::placeholder, .comments-area .comment-respond input[type=number]::placeholder, .comments-area .comment-respond textarea::placeholder {
  transition: all 0.7s;
}
.comments-area .comment-respond input[type=date]:focus::placeholder, .comments-area .comment-respond input[type=time]:focus::placeholder, .comments-area .comment-respond input[type=datetime-local]:focus::placeholder, .comments-area .comment-respond input[type=week]:focus::placeholder, .comments-area .comment-respond input[type=month]:focus::placeholder, .comments-area .comment-respond input[type=text]:focus::placeholder, .comments-area .comment-respond input[type=email]:focus::placeholder, .comments-area .comment-respond input[type=url]:focus::placeholder, .comments-area .comment-respond input[type=password]:focus::placeholder, .comments-area .comment-respond input[type=search]:focus::placeholder, .comments-area .comment-respond input[type=tel]:focus::placeholder, .comments-area .comment-respond input[type=number]:focus::placeholder, .comments-area .comment-respond textarea:focus::placeholder {
  color: transparent;
}
.comments-area .comment-respond textarea {
  height: auto !important;
  padding-top: 15px;
}
.comments-area .comment-respond .comment-form-author {
  float: left;
  width: 50%;
  padding-right: 10px;
  margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-email {
  float: left;
  width: 50%;
  padding-left: 12px;
  margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-url {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-cookies-consent {
  width: 100%;
  float: left;
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
  margin-top: 10px;
}
.comments-area .comment-respond .comment-form-cookies-consent input {
  position: absolute;
  left: 0;
  top: 6px;
}
.comments-area .comment-respond .comment-form-cookies-consent label {
  display: inline-block;
  margin: 0;
  font-weight: normal;
}
.comments-area .comment-respond .form-submit {
  float: left;
  width: 100%;
}
.comments-area .comment-respond .form-submit input {
  background: #f7941d;
  border: 1px solid #f7941d;
  color: #ffffff;
  padding: 10px 25px;
  display: inline-block;
  cursor: pointer;
  text-transform: capitalize;
  transition: all 0.7s;
  border-radius: 0;
  box-shadow: none;
  font-size: 15px;
}
.comments-area .comment-respond .form-submit input:focus {
  outline: 0;
}
.comments-area .comment-respond .form-submit input:hover, .comments-area .comment-respond .form-submit input:focus {
  color: #ffffff;
  background: #8732de;
  border: 1px solid #8732de;
}

/*================================================
Widget Sidebar CSS
=================================================*/
.widget-area {
  padding-left: 10px;
}
.widget-area.widget-left-sidebar {
  padding-right: 10px;
  padding-left: 0;
}
.widget-area .widget {
  margin-bottom: 30px;
  background-color: #ffffff;
  padding: 25px;
}
.widget-area .widget:last-child {
  margin-bottom: 0;
}
.widget-area .widget .widget-title {
  margin-bottom: 25px;
  position: relative;
  padding-bottom: 7px;
  border-bottom: 1px solid #eeeeee;
  font-size: 18px;
  font-weight: 600;
}
.widget-area .widget .widget-title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 1px;
  bottom: -1px;
  background-color: #f7941d;
}
.widget-area .widget_search form {
  position: relative;
}
.widget-area .widget_search form .screen-reader-text {
  display: none;
}
.widget-area .widget_search form label {
  display: block;
  margin-bottom: 0;
}
.widget-area .widget_search form .search-field {
  height: 50px;
  color: #000;
  background-color: #f2f4f5;
  display: block;
  width: 100%;
  border-radius: 5px;
  padding: 0 0 0 15px;
  border: 1px solid #f2f4f5;
  box-shadow: none;
  transition: all 0.7s;
}
.widget-area .widget_search form .search-field:focus {
  outline: 0;
}
.widget-area .widget_search form .search-field::placeholder {
  transition: all 0.7s;
}
.widget-area .widget_search form .search-field:focus::placeholder {
  color: transparent;
}
.widget-area .widget_search form button {
  border: none;
  background-color: #ffffff;
  color: #f7941d;
  height: 40px;
  width: 40px;
  position: absolute;
  right: 5px;
  padding: 0;
  transition: all 0.7s;
  top: 5px;
  font-size: 20px;
}
.widget-area .widget_search form button i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.widget-area .widget_search form button:hover, .widget-area .widget_search form button:focus {
  border-radius: 5px;
  background-color: #f7941d;
  color: #ffffff;
}
.widget-area .widget_posts_thumb {
  position: relative;
  overflow: hidden;
}
.widget-area .widget_posts_thumb .item {
  overflow: hidden;
  margin-bottom: 15px;
}
.widget-area .widget_posts_thumb .item:last-child {
  margin-bottom: 0;
}
.widget-area .widget_posts_thumb .item .thumb {
  float: left;
  height: 80px;
  overflow: hidden;
  display: block;
  position: relative;
  width: 80px;
  margin-right: 15px;
  z-index: 1;
}
.widget-area .widget_posts_thumb .item .thumb .fullimage {
  width: 80px;
  height: 80px;
  display: inline-block;
  border-radius: 5px;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
}
.widget-area .widget_posts_thumb .item .thumb .fullimage.bg1 {
  background-image: url(assets/img//blog/blog1.jpg);
}
.widget-area .widget_posts_thumb .item .thumb .fullimage.bg2 {
  background-image: url(assets/img//blog/blog2.jpg);
}
.widget-area .widget_posts_thumb .item .thumb .fullimage.bg3 {
  background-image: url(assets/img//blog/blog3.jpg);
}
.widget-area .widget_posts_thumb .item .thumb::before, .widget-area .widget_posts_thumb .item .thumb::after {
  transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
  content: "";
  background-color: #ffffff;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
}
.widget-area .widget_posts_thumb .item .thumb::before {
  width: 40px;
  height: 1px;
  left: 100%;
}
.widget-area .widget_posts_thumb .item .thumb::after {
  height: 40px;
  width: 1px;
  top: 0;
}
.widget-area .widget_posts_thumb .item .info {
  overflow: hidden;
  margin-top: 5px;
}
.widget-area .widget_posts_thumb .item .info span {
  display: block;
  color: #f7941d;
  text-transform: uppercase;
  margin-top: -2px;
  margin-bottom: 5px;
  font-size: 13px;
}
.widget-area .widget_posts_thumb .item .info .title {
  margin-bottom: 0;
  line-height: 1.4;
  font-size: 15px;
  font-weight: 600;
}
.widget-area .widget_posts_thumb .item .info .title a {
  display: inline-block;
  color: #301d44;
}
.widget-area .widget_posts_thumb .item .info .title a:hover {
  color: #f7941d;
}
.widget-area .widget_posts_thumb .item:hover .thumb::before, .widget-area .widget_posts_thumb .item:hover .thumb::after {
  opacity: 1;
  top: 50%;
  left: 50%;
}
.widget-area .widget_categories ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_categories ul li {
  position: relative;
  margin-bottom: 12px;
  overflow: hidden;
  color: #000;
  padding-left: 18px;
  font-size: 15px;
}
.widget-area .widget_categories ul li:last-child {
  margin-bottom: 0;
}
.widget-area .widget_categories ul li::before {
  background: #f7941d;
  height: 8px;
  width: 8px;
  content: "";
  border-radius: 50%;
  left: 0;
  top: 7px;
  position: absolute;
}
.widget-area .widget_categories ul li a {
  color: #000;
  display: inline-block;
}
.widget-area .widget_categories ul li a:hover {
  color: #f7941d;
}
.widget-area .widget_categories ul li .post-count {
  font-size: 14px;
  display: inline-block;
  position: relative;
  top: 1px;
}
.widget-area .widget_tag_cloud .widget-title {
  margin-bottom: 17px;
}
.widget-area .tagcloud a {
  display: inline-block;
  background: #f5f5f5;
  color: #000;
  padding: 7px 15px;
  border: none;
  border-radius: 3px;
  font-size: 14px !important;
  margin-top: 8px;
  margin-right: 4px;
}
.widget-area .tagcloud a:hover, .widget-area .tagcloud a:focus {
  color: #ffffff;
  background-color: #f7941d;
}
.widget-area .widget_insight ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_insight ul li {
  margin-bottom: -1px;
}
.widget-area .widget_insight ul li a {
  position: relative;
  display: block;
  padding: 15px 25px 15px 40px;
  color: #000;
  border: 1px solid #eeeeee;
  font-weight: 700;
  font-size: 18px;
}
.widget-area .widget_insight ul li a::before {
  width: 5px;
  height: 5px;
  transition: all 0.7s;
  background-color: #f7941d;
  content: "";
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}
.widget-area .widget_insight ul li a:hover, .widget-area .widget_insight ul li a.active {
  border-color: #f7941d;
  background-color: #f7941d;
  color: #ffffff;
}
.widget-area .widget_insight ul li a:hover::before, .widget-area .widget_insight ul li a.active::before {
  background-color: #ffffff;
}
.widget-area .widget_insight ul li.active a {
  border-color: #f7941d;
  background-color: #f7941d;
  color: #ffffff;
}
.widget-area .widget_insight ul li.active a::before {
  background-color: #ffffff;
}

/*================================================
Single Blog Area
=================================================*/
.service-details-text .service-image {
  margin-bottom: 30px;
}
.service-details-text .service-image img {
  border-radius: 5px;
}
.service-details-text h1 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 10px;
}
.service-details-text h2 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 10px;
}
.service-details-text h3 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
}
.service-details-text h4 {
  font-size: 18px;
  font-weight: 600;
}
.service-details-text h5 {
  font-size: 16px;
  font-weight: 600;
}
.service-details-text h6 {
  font-size: 15px;
  font-weight: 600;
}
.service-details-text .image {
  margin: 20px 0;
}
.service-details-text .image img {
  border-radius: 5px;
}

.service-sidebar .title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}
.service-sidebar ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.service-sidebar ul li {
  margin-bottom: 15px;
}
.service-sidebar ul li:last-child {
  margin-bottom: 0;
}
.service-sidebar ul li a {
  background-color: #f9f8fb;
  padding: 15px 20px 15px 50px;
  border-radius: 5px;
  transition: all 0.7s;
  position: relative;
  color: #301d44;
  display: block;
}
.service-sidebar ul li a:hover {
  background-color: #301d44;
  color: #ffffff;
}
.service-sidebar ul li i {
  color: #f7941d;
  position: absolute;
  left: 20px;
  top: 13px;
  font-size: 23px;
}

/*================================================
Contact Area
=================================================*/
.contact-info {
  padding: 30px;
  margin-bottom: 30px;
  text-align: center;
  border: 1px solid #eeeeee;
}
.contact-info i {
  background-color: #fff4e5;
  color: #f7941d;
  width: 70px;
  height: 70px;
  font-size: 30px;
  line-height: 70px;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 20px;
}
.contact-info h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}
.contact-info p {
  margin-bottom: 0;
}
.contact-info p a {
  color: #6c6377;
}
.contact-info p a:hover {
  color: #f7941d;
}

.contact-form-area .section-title {
  margin-top: -8px;
}

.contact-form {
  padding: 40px;
  box-shadow: 0 0 20px rgba(158, 158, 158, 0.16);
  background-color: #ffffff;
}
.contact-form form .form-group {
  margin-bottom: 25px;
}
.contact-form form .form-group .form-control {
  height: 55px;
  color: #301d44;
  box-shadow: unset !important;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  transition: all 0.7s;
  border-radius: 3px;
  padding: 12px 20px;
  font-size: 15px;
}
.contact-form form .form-group textarea.form-control {
  height: auto;
}
.contact-form form .default-btn-one {
  margin-top: 5px;
  font-weight: 500;
}

/*================================================
Error Area
=================================================*/
.error-area .error {
  text-align: center;
}
.error-area .error .error-image {
  margin-bottom: 20px;
}
.error-area .error h2 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-bottom: 20px;
}
.error-area .error .error-btn a {
  display: inline-block;
  transition: all 0.7s;
  font-size: 36px;
  font-weight: 600;
  color: #301d44;
}
.error-area .error .error-btn a:hover {
  color: #f7941d;
}

/*================================================
Gallery Area
=================================================*/
.gallery-area .gallery-title {
  text-align: center;
  margin-bottom: 30px;
}
.gallery-area .floza-gallery {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
}
.gallery-area .floza-gallery .caption {
  text-align: center;
  position: absolute;
  background: rgba(48, 29, 68, 0.7803921569);
  width: 100%;
  height: 100%;
  top: 0;
  left: 100%;
  transition: all 0.7s;
}
.gallery-area .floza-gallery .caption a i {
  color: #f7941d;
  font-size: 30px;
}
.gallery-area .floza-gallery:hover .caption {
  left: 0;
}

/*================================================
Faq Area
=================================================*/
.faq-area .faq-contant {
  padding-bottom: 70px;
}
.faq-area .faq-contant .accordion {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.faq-area .faq-contant .accordion li {
  position: relative;
  border: 1px solid #dcdcdc;
  margin-bottom: 20px;
}
.faq-area .faq-contant .accordion li:last-child {
  margin-bottom: 0;
}
.faq-area .faq-contant .accordion li .title {
  margin: 0;
  cursor: pointer;
  font-size: 15px;
  padding: 20px 15px 20px 80px;
  text-transform: uppercase;
  position: relative;
}
.faq-area .faq-contant .accordion li .title::before {
  content: "";
  background-color: #eeeeee;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 60px;
}
.faq-area .faq-contant .accordion li .title:after {
  width: 10px;
  height: 10px;
  border-right: 1px solid #312f2f;
  border-bottom: 1px solid #312f2f;
  position: absolute;
  left: 22px;
  content: " ";
  top: 23px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.faq-area .faq-contant .accordion li .title.active {
  border-bottom: 1px solid #dcdcdc;
}
.faq-area .faq-contant .accordion li .title.active:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.faq-area .faq-contant .accordion li .accordion-content {
  display: none;
  padding: 20px 20px;
}
.faq-area .faq-contant .faq-image {
  padding-left: 50px;
  text-align: center;
}
.faq-area .faq-form {
  max-width: 1000px;
  margin: auto;
  padding: 50px;
  box-shadow: 0 0 20px 0px #dcdcdc;
}
.faq-area .faq-form .section-title {
  margin-bottom: 30px;
}
.faq-area .faq-form .form-group {
  margin-bottom: 20px;
}
.faq-area .faq-form .form-group label {
  color: #000000;
  font-weight: 500;
}
.faq-area .faq-form .form-control {
  display: block;
  width: 100%;
  height: auto;
  box-shadow: none;
  padding: 15px;
  font-size: 15px;
}
.faq-area .faq-form .faq-form-btn {
  background: #f7941d;
  color: #ffffff;
  border: 0;
  padding: 18px 35px;
  margin-top: 10px;
  border-radius: 5px;
  text-align: center;
  transition: all 0.7s;
  font-weight: 500;
}
.faq-area .faq-form .faq-form-btn:hover {
  background: #f21860;
  color: #ffffff;
}

/*================================================
Sign In Area
=================================================*/
.sign-in-area .sign-in-form {
  max-width: 650px;
  padding: 40px 50px;
  border-radius: 5px;
  margin: auto;
  box-shadow: 0px 0px 20px 0px #d8d8d8;
}
.sign-in-area .sign-in-form .form-group {
  margin-bottom: 25px;
}
.sign-in-area .sign-in-form .form-control {
  height: 55px;
  padding: 6px 20px;
  font-size: 14px;
  background: #fbfbfb;
  border: 1px solid #efefef;
  border-radius: 0;
  box-shadow: none;
}
.sign-in-area .sign-in-form .account-decs {
  text-align: center;
  margin-top: 20px;
}
.sign-in-area .sign-in-form .account-decs a {
  color: #f7941d;
  margin-left: 5px;
}
.sign-in-area .sign-in-form .account-decs a:hover {
  color: #301d44;
}

.form-check-input {
  margin-top: 3px;
}

/*================================================
Sign Up Area
=================================================*/
.sign-up-area .sign-up-form {
  max-width: 650px;
  padding: 40px 50px;
  border-radius: 5px;
  margin: auto;
  box-shadow: 0px 0px 20px 0px #d8d8d8;
}
.sign-up-area .sign-up-form .form-group {
  margin-bottom: 25px;
}
.sign-up-area .sign-up-form .form-control {
  height: 55px;
  padding: 6px 20px;
  font-size: 14px;
  background: #fbfbfb;
  border: 1px solid #efefef;
  border-radius: 0;
  box-shadow: none;
}
.sign-up-area .sign-up-form .account-decs {
  text-align: center;
  margin-top: 20px;
}
.sign-up-area .sign-up-form .account-decs a {
  color: #f7941d;
  margin-left: 5px;
}
.sign-up-area .sign-up-form .account-decs a:hover {
  color: #301d44;
}

/*================================================
Privacy Policy Area
=================================================*/
.privacy-policy .privacy-policy-text h2 {
  font-size: 20px;
  margin-bottom: 13px;
  margin-top: 5px;
}
.privacy-policy .privacy-policy-text p {
  margin-bottom: 15px;
}
.privacy-policy .privacy-policy-text p:last-child {
  margin-bottom: 0;
}

/*================================================
Coming Soon Area
=================================================*/
.coming-soon-area {
  position: relative;
  height: 100vh;
  overflow: hidden;
}
.coming-soon-area .coming-soon-contant {
  text-align: center;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 70px 30px;
}
.coming-soon-area .coming-soon-contant h1 {
  margin-bottom: 20px;
  font-size: 50px;
  margin-top: 0;
}
.coming-soon-area .coming-soon-contant p {
  font-size: 16px;
  max-width: 600px;
  margin: 0px auto 20px auto;
}
.coming-soon-area .coming-soon-contant #timer {
  margin-top: 30px;
  margin-bottom: 30px;
}
.coming-soon-area .coming-soon-contant #timer div {
  display: inline-block;
  color: #301d44;
  position: relative;
  margin-left: 35px;
  margin-right: 35px;
  font-size: 45px;
  font-weight: 700;
}
.coming-soon-area .coming-soon-contant #timer div span {
  display: block;
  text-transform: capitalize;
  margin-top: -15px;
  font-size: 16px;
  font-weight: normal;
}
.coming-soon-area .coming-soon-contant #timer div:last-child {
  margin-right: 0;
}
.coming-soon-area .coming-soon-contant #timer div:last-child::before {
  display: none;
}
.coming-soon-area .coming-soon-contant #timer div:first-child {
  margin-left: 0;
}
.coming-soon-area .coming-soon-contant #timer div::before {
  content: "";
  position: absolute;
  right: -50px;
  top: -10px;
  font-size: 70px;
  color: #ffffff;
}
.coming-soon-area .coming-soon-contant .coming-soon-form {
  position: relative;
}
.coming-soon-area .coming-soon-contant .coming-soon-form .form-control {
  height: auto;
  padding: 15px 10px;
  margin-bottom: 30px;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 70%;
  margin: 0 auto 30px;
}
.coming-soon-area .coming-soon-contant .coming-soon-form .form-btn {
  background: #301d44;
  color: #ffffff;
  padding: 17px 30px;
  border: 0;
  border-radius: 5px;
  position: absolute;
  top: 0;
  right: 15%;
  transition: all 0.7s;
}
.coming-soon-area .coming-soon-contant .coming-soon-form .form-btn:hover {
  background: #f7941d;
  color: #ffffff;
}
.coming-soon-area .coming-soon-contant ul {
  padding: 0;
  margin-bottom: 0;
  margin-top: 15px;
  list-style-type: none;
}
.coming-soon-area .coming-soon-contant ul li {
  display: inline-block;
  margin-right: 5px;
}
.coming-soon-area .coming-soon-contant ul li i {
  background: #301d44;
  color: #ffffff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 5px;
  font-size: 20px;
  transition: all 0.7s;
}
.coming-soon-area .coming-soon-contant ul li i:hover {
  background: #f7941d;
  color: #ffffff;
}